import React, { useCallback, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Main from '../Main'
import Filter from '../../utils/Filter/FilterTimeCheck'
import { get as getUsers } from '../../actions/user'
import { globalStyles } from '../../styles/global'
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import useStyles from './useStyles'
import { Create as CreateIcon } from '@material-ui/icons'
import CustomSelect from './components/CustomSelect'
import api from '../../../server'
import { format } from 'date-fns'
import getDayOfWeekName, {
  formatDate,
  formatDateToBrazilTimeZone,
  formatTimeToHHMM,
} from './utils/FomatterDatas'
import Load from '../../utils/Loading'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import Toast from '../../utils/Toast'
import moment from 'moment'
import { extractNameFromURLParameters } from './utils/FunctionsUtils'

const TABLE_HEADER = ['Data', 'Dia', 'Usuário', 'Horas', 'Status', '', 'Ações']

const TimeCheck = props => {
  const { listUsers, getUsers } = props

  function convertNullString(value) {
    return value === 'null' ? null : value
  }
  const classes = useStyles()
  const styles = globalStyles()
  const history = useHistory()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  // const dateInitFilter = searchParams.get('dataInicialFilter')
  let dateFinalFilter = formatDateToBrazilTimeZone(
    searchParams.get('dataFinalFilter'),
    1
  )
  const [dateInitFilter, setDateInitFilter] = useState(
    searchParams.get('dataInicialFilter')
  )
  const userInitFilterB = searchParams.get('userInitFilter')
  const userFinalFilterB = searchParams.get('userFinalFilter')
  const statusFilter = searchParams.get('statusFilter')
  const pageFilter = searchParams.get('pageFilter')
  const rowsFilter = searchParams.get('rowsFilter')

  const { userFinalFilter } = extractNameFromURLParameters(
    convertNullString(userInitFilterB),
    convertNullString(userFinalFilterB)
  )
  const labelUser =
    convertNullString(userFinalFilter) !== null ? userFinalFilter : null

  const callFilter = searchParams.get('callFilter')
  const [callFilterFinal, setCallFilterFinal] = useState(callFilter)
  const [statusReturn, setStatusReturn] = useState()
  const [dateReturnInit, setDateReturnInit] = useState()
  const [dateReturnFinal, setDateReturnFinal] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [selectedOption, setSelectedOption] = useState({})
  const [startUserFilter, setStartUserFilter] = useState(null)
  const [endUserFilter, setEndUserFilter] = useState(null)
  const [userData, setUserData] = useState([])
  const [filteredUserData, setFilteredUserData] = useState([])
  const tableHeaderColor = '#3f51b5'

  const [currentPage, setCurrentPage] = useState(0)
  const [params, setParams] = useState({
    startDate:
      dateInitFilter !== 'null'
        ? formatDateToBrazilTimeZone(dateInitFilter, 1)
        : null,
    endDate: dateFinalFilter !== 'null' ? dateFinalFilter : null,
    startUsername: labelUser,
    endUsername: labelUser,
    status: convertNullString(statusFilter) !== null ? statusFilter : null,
    page: pageFilter !== 'null' ? Number(pageFilter) : 0,
    size: rowsFilter !== 'null' ? Number(rowsFilter) : 0,
  })
  const [totalElements, setTotaleElements] = useState(0)
  const [callLoadingInit, setCallLoadingInit] = useState(
    callFilter !== 'null' ? true : false
  )

  const [loading, setLoading] = useState(false)
  const [datePeriod, setDatePeriod] = useState([])
  const [toastOpen, setToastOpen] = useState(false)
  const [toastOpenError, setToastOpenError] = useState(false)
  const [initDateFilter, setInitDateFilter] = useState(
    moment().format('MM/01/YYYY')
  )
  const [finalDateFilter, setFinalDateFilter] = useState(
    moment(new Date())
      .endOf('month')
      .format('LL')
  )
  const [clickFilter, setClickFilter] = useState(
    callFilter === 'true' ? true : false
  )

  const userOptions = listUsers
    .filter(item => item.indInativo === true) // Filtra os itens com ind_inativo diferente de 1
    .map(item => ({
      value: item.desUsuario,
      label: item.desUsuario,
    }))

  const clearUrlParameters = () => {
    const newUrl = window.location.origin + window.location.pathname
    window.history.replaceState({}, document.title, newUrl)
  }

  const options = [
    { value: false, label: 'Aberto' },
    { value: true, label: 'Fechado' },
    { value: null, label: '' },
  ]

  const statusOptions = [
    { value: false, label: 'Aberto', icon: <ClearIcon /> },
    { value: true, label: 'Fechado', icon: <CheckIcon /> },
  ]

  const [labelStatusEnd, setLabelStatusEnd] = useState('Status')

  const filterFields = [
    {
      type: 'date',
      name: 'startDate',
      label: 'Data Inicial',
      initial:
        clickFilter === true
          ? formatDateToBrazilTimeZone(dateInitFilter, 2)
          : moment().format('MM/01/YYYY'),
      maxDate: finalDateFilter !== null ? finalDateFilter : undefined,
      onChange: e => setInitDateFilter(e || undefined),
    },
    {
      type: 'date',
      name: 'endDate',
      label: 'Data Final',
      initial:
        clickFilter === true
          ? formatDateToBrazilTimeZone(dateFinalFilter, 2)
          : moment(new Date())
              .endOf('month')
              .format('LL'),
      minDate: initDateFilter !== null ? initDateFilter : undefined,
      onChange: e => setFinalDateFilter(e || null),
    },
    {
      type: 'select',
      name: 'startUser',
      label: 'Usuário',
      options: userOptions.sort((a, b) => a.label.localeCompare(b.label)),
      initial: labelUser,
      value: startUserFilter,
    },
    {
      type: 'select',
      name: 'status',
      label: labelStatusEnd,
      options: statusOptions.sort((a, b) => a.label.localeCompare(b.label)),
      value: statusReturn,
    },
  ]

  const loadDatePeriod = useCallback(async () => {
    const { data } = await api.get('/period')
    setDatePeriod(data.data)
  }, [])

  const loadData = useCallback(async () => {
    try {
      setLoading(true)

      const { data } = await api.get('/timecheck', { params })
      if (data.totalElements === 0) {
        setClickFilter(false)
      }
      const filteredData = data.content.filter(rowData => {
        return true
      })
      if (data.totalElements === 0) {
        setClickFilter(false)
      }
      if (callFilterFinal === 'true') {
        setInitDateFilter(undefined)
        if (convertNullString(statusFilter) === 'true') {
          setLabelStatusEnd('Fechado')
        }
        if (convertNullString(statusFilter) === 'false') {
          setLabelStatusEnd('Aberto')
        }
      }
      setTotaleElements(data.totalElements)
      setUserData(data.content)
      setFilteredUserData(filteredData)
      clearUrlParameters()
    } catch (error) {
      setLoading(false)

      console.error('Error loading data:', error)
    } finally {
      setLoading(false)
    }
  }, [params])

  const handleFilterSubmit = useCallback(
    async (fields, filtrosLimpos) => {
      setLabelStatusEnd('Status')
      setCallFilterFinal(false)
      setLoading(true)
      setCallLoadingInit(true)
      if (filtrosLimpos.filtrosLimpos === true) {
        setInitDateFilter(null)
        setFinalDateFilter(null)
        setCurrentPage(0)
        setTotaleElements(0)
        setClickFilter(false)
        setLoading(false)
        return
      } else {
        setClickFilter(true)
        setCurrentPage(0)
      }

      if (labelStatusEnd === 'Aberto') {
        fields.status = false
      }

      if (labelStatusEnd === 'Fechado') {
        fields.status = true
      }
      setClickFilter(true)
      setCurrentPage(0)

      const startDateValue = fields.startDate
        ? format(new Date(fields.startDate), 'yyyy-MM-dd')
        : null

      const endDateValue = fields.endDate
        ? format(new Date(fields.endDate), 'yyyy-MM-dd')
        : null

      setDateReturnInit(startDateValue)
      setDateReturnFinal(endDateValue)
      setInitDateFilter(formatDateToBrazilTimeZone(startDateValue, 2))

      setFinalDateFilter(formatDateToBrazilTimeZone(endDateValue, 2))

      setParams({
        startDate: startDateValue,
        endDate: endDateValue,
        startUsername: fields.startUser !== null ? fields.startUser : null,
        endUsername: fields.startUser !== null ? fields.startUser : null,
        status: fields.status,
        page: 0,
        size: rowsPerPage,
      })
    },
    [labelStatusEnd, rowsPerPage]
  )

  const handleUserFilterChange = (fieldName, value) => {
    if (fieldName === 'startUser') {
      setStartUserFilter(value)
    } else if (fieldName === 'endUser') {
      setEndUserFilter(value)
    } else if (fieldName === 'status') {
      setStatusReturn(value)
    } else if (fieldName === 'startDate') {
      if (value === null) {
        setDateInitFilter(value)
      } else if (!isNaN(Date.parse(value))) {
        setDateInitFilter(value)
      } else {
        console.log('A variável não é uma data válida ou nula.')
      }
    } else if (fieldName === 'endDate') {
      if (value === null) {
        console.log(value)
        dateFinalFilter = value
        setFinalDateFilter(undefined)
      } else if (!isNaN(Date.parse(value))) {
        console.log(value)

        setFinalDateFilter(value)
      } else {
        console.log('A variável não é uma data válida ou nula.')
      }
    }
  }

  const handleChange = useCallback(
    async (value, user) => {
      setCallLoadingInit(true)
      setSelectedOption(prevSelectedOption => ({
        ...prevSelectedOption,
        [user.seqConferencia]: value,
      }))

      try {
        const updateData = {
          seqConferencia: Number(user.seqConferencia),
          indStatus: Boolean(value),
        }

        await api.put('timecheck/update-status', updateData)
        setParams({
          ...params,
        })
        setTimeout(() => {
          setToastOpen(true)
        }, 4000)
      } catch (error) {
        console.error('Erro ao atualizar o status:', error)
        setToastOpenError(true)
      }
    },
    [params]
  )

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setToastOpen(false)
  }

  const handlePageChange = (event, newPage) => {
    setCallLoadingInit(true)
    setCurrentPage(newPage)

    setParams({
      ...params,
      page: newPage,
    })
  }

  const handlerRowsChange = event => {
    setCallLoadingInit(true)

    const newRowsPerPage = parseInt(event.target.value)
    setRowsPerPage(parseInt(event.target.value, 10))
    setParams({
      ...params,
      size: Number(newRowsPerPage),
    })
  }

  useEffect(() => {}, [
    filteredUserData,
    startUserFilter,
    labelUser,
    callFilterFinal,
    endUserFilter,
    dateInitFilter,
  ])

  useEffect(() => {}, [userData, datePeriod, rowsPerPage, callLoadingInit])

  useEffect(() => {
    if (clickFilter === true) {
      if (dateInitFilter !== null || dateInitFilter !== 'null') {
        setInitDateFilter(dateInitFilter)
      } else {
        setInitDateFilter(undefined)
      }
      const loadInitialData = async () => {
        await loadDatePeriod()
        await loadData()
      }
      if (callLoadingInit === true) {
        loadInitialData()
        setCallLoadingInit(false)
      }

      if (callFilter === true || callFilter === 'true') {
        if (dateInitFilter !== null || dateInitFilter !== 'null') {
          setInitDateFilter(dateInitFilter)
        } else {
          setInitDateFilter(undefined)
        }
        if (dateFinalFilter !== null || dateFinalFilter !== 'null') {
          setFinalDateFilter(formatDateToBrazilTimeZone(dateFinalFilter, 2))
        } else {
          setFinalDateFilter(undefined)
        }
      }
    }

    setLoading(false)
  }, [
    loadData,
    params,
    currentPage,
    location.search,
    totalElements,
    statusReturn,
    labelStatusEnd,
    initDateFilter,
    finalDateFilter,
  ])

  useEffect(() => {
    requestUser()
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  const handleClickRowAction = rowData => () => {
    onClickRowAction(rowData)
  }
  const onClickRowAction = row => {
    const userInitFilter = params.startUsername || null
    const userFinalFilter = params.endUsername || null
    const pageFilter = currentPage
    const buttonConfVerify = true

    if (dateReturnInit === undefined || dateReturnInit === 'undefined') {
      console.log('Parametros ', formatDateToBrazilTimeZone(dateInitFilter, 1))

      listUsers.map(item => {
        if (item.desUsuario === row.nomeUsuario) {
          const queryParams = new URLSearchParams({
            idUser: item.id,
            dtInicial: row.dtaLancamento,
            dtFinal: row.dtaLancamento,
            fromEntryReport: true,
            dataInicialFilter: formatDateToBrazilTimeZone(dateInitFilter, 1),
            dataFinalFilter: formatDateToBrazilTimeZone(dateFinalFilter, 1),
            userInitFilter: userInitFilter,
            userFinalFilter: userFinalFilter,
            statusFilter: statusReturn,
            pageFilter: pageFilter,
            rowsFilter: rowsPerPage,
            buttonConfVerify: buttonConfVerify,
          })

          history.push(`/entries?${queryParams}`)
        }
        return null
      })
      return
    }

    listUsers.map(item => {
      if (item.desUsuario === row.nomeUsuario) {
        const queryParams = new URLSearchParams({
          idUser: item.id,
          dtInicial: row.dtaLancamento,
          dtFinal: row.dtaLancamento,
          fromEntryReport: true,
          dataInicialFilter: dateReturnInit,
          dataFinalFilter: dateReturnFinal,
          userInitFilter: userInitFilter,
          userFinalFilter: userFinalFilter,
          statusFilter: statusReturn,
          pageFilter: pageFilter,
          rowsFilter: rowsPerPage,
          buttonConfVerify: buttonConfVerify,
        })

        history.push(`/entries?${queryParams}`)
      }
      return null
    })
  }

  return (
    <Main title="Conferência de Horas">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}></Box>
        <Box component="div" className={styles.filter} xs={12}>
          <Filter
            fields={filterFields}
            onSubmit={handleFilterSubmit}
            onValueChange={handleUserFilterChange}
          />
        </Box>
        <TableContainer color="primary" component={Paper}>
          <Toast
            open={toastOpen}
            onClose={handleCloseToast}
            success="Ação salva com sucesso"
          />
          <Toast
            open={toastOpenError}
            onClose={handleCloseToast}
            error="Ocorreu um erro na operação."
          />

          {loading ? (
            <Load />
          ) : filteredUserData !== null ? (
            <Table>
              <TableHead
                style={{ background: tableHeaderColor, color: 'white' }}
              >
                <TableRow className={styles.tableHeader}>
                  {TABLE_HEADER.map(header => (
                    <TableCell style={{ color: 'white' }} key={header}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {clickFilter !== false ? (
                <TableBody>
                  {filteredUserData !== null &&
                    filteredUserData.slice(0, rowsPerPage).map(rowData => {
                      return (
                        <TableRow
                          style={{
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                          }}
                          key={rowData.seqUsuario}
                        >
                          <TableCell>
                            {formatDate(rowData.dtaLancamento)}
                          </TableCell>
                          <TableCell>
                            <div className={classes.name}>
                              {getDayOfWeekName(rowData.dtaLancamento)}
                            </div>
                          </TableCell>
                          <TableCell>{rowData.nomeUsuario}</TableCell>
                          <TableCell>
                            {formatTimeToHHMM(rowData.hora)}
                          </TableCell>

                          <TableCell>
                            {rowData.indStatus === true ? (
                              <CheckIcon className={classes.greenIcon} />
                            ) : (
                              <ClearIcon className={classes.redIcon} />
                            )}
                          </TableCell>

                          <TableCell>
                            <div className={classes.row}>
                              <div
                                className={classes.rowDetail}
                                onClick={handleClickRowAction(rowData)}
                              >
                                Detalhes{' '}
                                <CreateIcon
                                  onClick={handleClickRowAction(rowData)}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <CustomSelect
                              className={classes.select}
                              name="status"
                              options={options}
                              label={
                                rowData.indStatus === true
                                  ? 'Fechado'
                                  : 'Aberto'
                              }
                              stateValues={
                                selectedOption[rowData.seqConferencia] || ''
                              }
                              setValueToField={value =>
                                handleChange(value, rowData)
                              }
                              width={150}
                              height={55}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" justify="center" colspan="12">
                      <strong>Nenhum registro encontrado</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          ) : (
            <Typography variant="h6" style={{ padding: '16px' }}>
              Nenhum registro encontrado
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          component="div"
          count={totalElements}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handlerRowsChange}
          labelRowsPerPage="Registros por página:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
        />
      </Grid>
    </Main>
  )
}

const mapStateToProps = state => {
  const { userReducer } = state

  return {
    ...userReducer,
    listUsers: userReducer.listUsers,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsers,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TimeCheck)
