import axios from "axios";

import  { baseUrl } from "../config/URL";


const user = JSON.parse(localStorage.getItem('user')) || {}



const api = axios.create({
    baseURL: baseUrl, 
    headers: {
      'Content-Type': 'application/json', 
      Authorization: user.dsToken,
    },
  });

 export const apiWithoutToken = axios.create({
    baseURL: baseUrl, 
    headers: {
      'Content-Type': 'application/json', 
      
    },
  });


  export default api;