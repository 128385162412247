import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useFormik } from 'formik'

import { Box, TextField } from '@material-ui/core/'
import { Autocomplete } from '@material-ui/lab'

import * as actionActivity from '../../actions/activity'
import { get as getUsers } from '../../actions/user'
import { saveVacation } from '../../actions/entry'
import Main from '../Main'
import FlexWrapper from '../../utils/Wrapper/FlexWrapper'
import FlexItemWrapper from '../../utils/Wrapper/FlexItemWrapper'
import Datepicker from '../../utils/Form/Datepicker'
import Button from '../../utils/Form/Button'
import { validateValues } from './validations'
import Toast from '../../utils/Toast'

const NO_ACTIVITY_TEXT = 'Nenhuma atividade encontrada'
const NO_USERS_TEXT = 'Nenhum usuário encontrado'
const ACTIVITY_DEV_CODE = 'dev-'
const ADMIN_ROLE = 'ADMINISTRADOR'
const COORDINATOR_ROLE = 'COORDENADOR'
const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro ao salvar o lançamento'
const DEFAULT_SUCCESS_MESSAGE = 'O lançamento foi salvo com sucesso'

const getDateTime = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  return date
}

const user = JSON.parse(localStorage.getItem('user'))

const Vacation = props => {
  const {
    listActivities,
    getActivities,
    saveVacation,
    listUsers,
    getUsers,
  } = props
  const [filteredActivities, setFilteredActivities] = React.useState([])

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { office } = user || {}
  const isAdminOrCoordinator =
    office.tipCargo === ADMIN_ROLE || office.tipCargo === COORDINATOR_ROLE

  const resolveListUsers = () => {
    if (isAdminOrCoordinator) {
      return listUsers
    }

    return listUsers.filter(user => user.office.id === office.id)
  }

  const formik = useFormik({
    initialValues: {
      activity: {},
      user: isAdminOrCoordinator ? {} : user,
      dthInitial: getDateTime(),
      dthFinal: getDateTime(),
      description: '',
    },
    validateOnChange: false,
    validate: validateValues,
    onSubmit: async values => {
      setError('')
      setSuccess('')
      setIsLoading(true)
      const response = await saveVacation({
        idActivity: values.activity.id,
        idUser: values.user.id,
        dthInitial: values.dthInitial,
        dthFinal: values.dthFinal,
        description: values.description,
      })
      setIsLoading(false)
      handleResponse(response)
    },
  })

  useEffect(() => {
    const devListActivities = listActivities.filter(activity =>
      activity && activity.sigAtividade.toLowerCase().startsWith(ACTIVITY_DEV_CODE)
    )
    setFilteredActivities(devListActivities)
  }, [listActivities])

  useEffect(() => {
    async function initialFetch() {
      await Promise.all([getActivities()])
    }
    initialFetch()
  }, [getActivities])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  const handleResponse = response => {
    const { cdStatus = 200, message } = response || {}
    const hasError = cdStatus >= 400 && cdStatus <= 500

    if (hasError) {
      setError(message.messageError || DEFAULT_ERROR_MESSAGE)
      return
    }

    setSuccess(DEFAULT_SUCCESS_MESSAGE)
    formik.resetForm()
  }

  const buildActivityLabel = activity => {
    const { sigAtividade, desAtividade = '' } = activity || {}

    if (!sigAtividade || !desAtividade) return ''

    return `${sigAtividade} - ${desAtividade}`
  }

  return (
    <Main isLoad={isLoading} title="Novo lançamento">
      <br />
      <Box maxWidth="900px">
        <form onSubmit={formik.handleSubmit}>
          <Autocomplete
            id="activity"
            name="activity"
            fullWidth
            noOptionsText={NO_ACTIVITY_TEXT}
            options={filteredActivities}
            getOptionLabel={buildActivityLabel}
            value={formik.values.activity}
            renderInput={params => (
              <TextField
                {...params}
                label="Atividade*"
                error={!!formik.errors.activityError}
                helperText={formik.errors.activityError}
              />
            )}
            onChange={(_, option) => {
              formik.setFieldValue('activity', option || {}).then(() => {
                formik.setFieldError('activityError', '')
              })
            }}
          />
          <br />
          <FlexWrapper>
            <FlexItemWrapper>
              <Datepicker
                id="dthInitial"
                name="dthInitial"
                label="Data início*"
                value={formik.values.dthInitial}
                onChange={time =>
                  formik.setFieldValue('dthInitial', time).then(() => {
                    formik.setFieldError('dthInitialError', '')
                  })
                }
                error={!!formik.errors.dthInitialError}
                helperText={formik.errors.dthInitialError}
              />
            </FlexItemWrapper>
            <FlexItemWrapper middle>
              <Datepicker
                id="dthFinal"
                name="dthFinal"
                label="Data final*"
                value={formik.values.dthFinal}
                onChange={time =>
                  formik.setFieldValue('dthFinal', time).then(() => {
                    formik.setFieldError('dthFinalError', '')
                  })
                }
                error={!!formik.errors.dthFinalError}
                helperText={formik.errors.dthFinalError}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Autocomplete
                id="user"
                name="user"
                fullWidth
                noOptionsText={NO_USERS_TEXT}
                options={resolveListUsers()}
                getOptionLabel={(option = {}) => {
                  return option.desUsuario || option.desEmail
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Usuário"
                    error={!!formik.errors.userError}
                    helperText={formik.errors.userError}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('user', option || {}).then(() => {
                    formik.setFieldError('userError', '')
                  })
                }}
                disabled={!isAdminOrCoordinator}
                value={formik.values.user}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <br />
          <TextField
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            multiline
            rows={5}
            variant="outlined"
            id="description"
            name="description"
            label="Descrição"
            onChange={({ target }) => {
              formik.setFieldValue('description', target.value).then(() => {
                formik.setFieldError('descriptionError', '')
              })
            }}
            value={formik.values.description}
            error={!!formik.errors.descriptionError}
            helperText={formik.errors.descriptionError}
          />
          <FlexWrapper>
            <FlexItemWrapper>
              <Button
                color="secondary"
                label="Cancelar"
                href="/atividades/home"
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Button color="primary" label="Salvar" type="submit" />
            </FlexItemWrapper>
          </FlexWrapper>
        </form>
      </Box>
      <Toast
        error={error}
        success={success}
        onClose={() => {
          setError('')
          setSuccess('')
        }}
        open={!!error || !!success}
      />
    </Main>
  )
}

const mapStateToProps = state => {
  const { activityReducer, userReducer } = state

  return {
    ...activityReducer,
    ...userReducer,
    listUsers: userReducer.listUsers,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getActivities: actionActivity.findWithExpectedDelivery,
      saveVacation,
      getUsers,
    },
    dispatch
  )

Vacation.propTypes = {
  /** Actions */
  getUsers: PropTypes.func.isRequired,
  getActivities: PropTypes.func.isRequired,
  saveVacation: PropTypes.func.isRequired,
  /** Reducer props */
  listActivities: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Vacation)
