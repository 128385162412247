/* >> COCONFIGURAÇÃO PARA RODAR O PROJETO UTILIZANDO OS ENDPOINTS COM BACK END EM EXECUÇÃO NO SERVIDOR << */

export  const baseUrl = require('../config/config-env.json')[
  process.env.REACT_APP_API_ENV || 'dev'
]

/* >> COCONFIGURAÇÃO PARA RODAR O PROJETO UTILIZANDO OS ENDPOINTS COM BACK END EM EXECUÇÃO NA MÁQUINA LOCAL << */
// export const baseUrl = 'http://localhost:8080/develcode-backend'

export default {
  activity: `${baseUrl}/activity`,
  activityImport: `${baseUrl}/activity/converter-csv`,
  authentication: `${baseUrl}/authentication`,
  allocation: `${baseUrl}/report/allocation`,
  client: `${baseUrl}/client`,
  clientPeriod: `${baseUrl}/client-period`,
  entry: `${baseUrl}/entry`,
  entryVacation: `${baseUrl}/entry/entry-vacation`,
  entryReport: `${baseUrl}/entry-report`,
  entryReportFechamentoAlocacaoRD: `${baseUrl}/entry-report-fechamento-alocacaoRD`,
  entryReportFechamentoAlocacaoSquadRD: `${baseUrl}/rd/squad`,
  entryAutomaticList: `${baseUrl}/entry/entry-listAutomation`,
  entryAutomaticExec: `${baseUrl}/entry/entry-executeAutomation`,
  employee: `${baseUrl}/employee`,
  hourBank: `${baseUrl}/hour-bank`,
  hourBankConsult: `${baseUrl}/hour-bank-consult`,
  monthlyClose: `${baseUrl}/monthly-close`,
  office: `${baseUrl}/office`,
  period: `${baseUrl}/period`,
  point: `${baseUrl}/point`,
  project: `${baseUrl}/project`,
  user: `${baseUrl}/user`,
  userPeriod: `${baseUrl}/user-period`,
  punchClockReportRD: `${baseUrl}/report/user-by-period`,
  statusHistory: `${baseUrl}/status-history`,
  password: `${baseUrl}/user/password`,
  periodicChangePassword: `${baseUrl}/user/periodicChangePassword`,
  proposal: `${baseUrl}/proposal`,
  proposalFunction: `${baseUrl}/function-proposal`,
  proposalUser: `${baseUrl}/proposal-user`,
  resetPassword: `${baseUrl}/user/reset-pass-email`,
  execution: `${baseUrl}/execution`,
  execUser: `${baseUrl}/execution-user`,
  timeCheck: `${baseUrl}/timecheck`,
  employeeQuery: `${baseUrl}/employee-search`,
  closureClients: `${baseUrl}/pre-closing`,
}
